import iconMoneyBack from '../../assets/images/icon-moneyback-guarantee-check.png';
import checkMarkGreen from '../../assets/images/checked-green.png';
import deleteRed from '../../assets/images/icon-delete-red2.png';
import exclamation from '../../assets/images/icon-exclamation-mark.png';

export const useStyles = (theme) => ({
	hide: {
		display: 'none !important',
	},
	textBold: {
		fontWeight: '700 !important',
	},
	textBig: {
		fontSize: '20px !important',
	},
	textRed: {
		color: '#E52866',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
	userBarWrapper: {
		background: '#ffffff',
		marginLeft: '-15px',
		marginRight: '-15px',
	},
	userBarContainer: {
		paddingTop: 15,
		paddingBottom: 15,
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	userBartem: {
		display: 'flex',
		alignItems: 'center',
		color: '#525B60',
		fontSize: 14,
		fontWeight: 500,
		'& svg': {
			marginRight: 5,
		}
	},
	userBarSeparator: {
		width: 0,
		height: 13,
		borderLeft: '1px solid #d4d6d7',
		margin: '0 10px',
	},
	topBanner: {
		background: '#D65050',
		padding: 10,
		boxSizing: 'border-box',
		cursor: 'pointer',
	},
	topBannerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: 1350,
	},
	topBannerText: {
		fontSize: 15,
		fontWeight: '700',
		lineHeight: '28px',
		color: '#ffffff',
	},
	topBannerCTA: {
		marginLeft: 10,
		fontSize: 15,
		fontWeight: '700',
		lineHeight: '17px',
		color: '#ffffff',
		background: '#FF8F00',
		boxShadow: '0px 0px 6px rgba(40, 51, 57, 0.3)',
		borderRadius: 20,
		padding: 11,
		minWidth: 150,
		textAlign: 'center',
	},
	bannerWrapper: {
		marginLeft: '-15px',
		marginRight: '-15px',
	},
	featuredOn: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		justifyContent: 'center',
		color: '#223770',
		fontSize: 22,
		fontWeight: 700,
		'& span': {
			marginRight: 10,
		}
	},
	vpnOverviewLogo: {
		maxWidth: 160,
	},
	lineThrough: {
		textDecoration: 'line-through',
	},
	pageWrapper: {
		padding: 48,
		marginTop: 32,
		marginBottom: 32,
		background: '#ffffff',
		borderRadius: 16,
	},
	plansWrapper: {

	},
	pageHeader: {
		padding: theme.spacing(7, 0, 4),
		color: '#283339',
		fontFamily: 'Spartan, sans-serif',
		'& h1': {
			fontSize: 44,
			fontWeight: 700,
			lineHeight: '58px',
			letterSpacing: '-1px',
			margin: '0 0 10px 0',
		},
		'& h2': {
			fontSize: 18,
			fontWeight: 600,
			lineHeight: '30px',
			letterSpacing: '-1px',
			margin: '0 auto',
		},
		'& p': {
			fontSize: 18,
			fontWeight: 700,
			color: '#525B60',
			fontFamily: 'Spartan, sans-serif',
			marginBottom: 30,
		}
	},
	contentHeader: {
		color: '#283339',
		fontSize: 36,
		fontWeight: 700,
		lineHeight: '36px',
		padding: '10px 15px 20px',
		marginBottom: 20,
	},
	withSubtitle: {
		'&$contentHeader': {
			marginBottom: 0,
		}
	},
	subTitle: {
		fontSize: 18,
		fontWeight: 700,
		textAlign: 'center',
		marginBottom: 40,
	},
	packagesWrapper: {
		paddingBottom: 60,
		marginBottom: 0,
	},
	packageContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: '420px !important',
	},
	cardWrapper: {
		position: 'relative',
		margin: 4,
		padding: 8,
		borderRadius: 10,
		background: '#93999C',
		width: '100%',
		cursor: 'pointer',
	},
	card: {
		position: 'relative',
		zIndex: 100,
		overflow: 'visible !important',
		borderRadius: 10,
		cursor: 'pointer',
		boxShadow: 'none',
		backgroundColor: '#f9f9f9',
		border: '4px solid transparent',
		minHeight: 330,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		padding: '50px 15px 30px',
		minWidth: 'calc(100% - 8px)',
	},
	cardHeaderBadge: {
		position: 'absolute',
		top: '-21px',
		left: 'calc(50% - 80px)',
		minWidth: '160px',
		textAlign: 'center',
		fontSize: 14,
		lineHeight: '24px',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
		padding: '6px 10px',
		background: '#28d799',
		zIndex: 2,
		color: '#fff',
		letterSpacing: '0px',
		borderRadius: 30,
	},
	cardHeader: {
		padding: 0,
		marginBottom: 6,
		'& span': {
			color: '#525B60',
			fontSize: 18,
			fontWeight: 700,
			fontFamily: 'Spartan, sans-serif',
			letterSpacing: '-0.5px',
		}
	},
	cardContent: {
		width: '100%',
		padding: '0px !important',
	},
	cardUserPercentage: {
		left: 0,
		right: 0,
		bottom: '-44px',
		position: 'absolute',
		fontSize: '13px',
		fontWeight: 700,
		textAlign: 'center',
		color:'#1a235b',
		padding: 9,
		background: '#e1f8f1',
		borderRadius: '6px',
	},
	cardUserPercentageIcon: {
		transform: 'rotate(-81deg)',
		display: 'inline-block',
		fontSize: '12px !important',
	},
	planPriceWrapper: {
		marginLeft: '-16px',
		marginRight: '-16px',
		paddingLeft: 16,
		paddingRight: 16,
		textAlign: 'center',
	},
	planPrice: {
		fontSize: 48,
		color: '#525B60',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
		textAlign: 'center',
		'& $currency': {
			fontSize: 20,
			position: 'relative',
			top: '-14px',
		},
		'& $month': {
			fontSize: 14,
		},
	},
	planPriceShort: {
		fontSize: 18,
		marginTop: 5,
		marginBottom: 15,
	},
	planOldPrice: {
		fontSize: 15,
		lineHeight: '24px',
		letterSpacing: '-0.3px',
		color: '#A6A9AB',
		fontWeight: 600,
		fontFamily: 'Spartan, sans-serif',
		textAlign: 'center',
		marginBottom: 10,
		'& p': {
			margin: '3px 0 0 0',
		}
	},
	planShowBill: {
		'& $planOldPrice': {
			marginBottom: 18,
		},
		'& $cardHeader': {
			marginBottom: 25,
		}
	},
	planCustomTotalPrice: {
		fontSize: 16,
		color: '#31c261',
		fontWeight: 700,
	},
	planOldTotalPriceDeleted: {
		color: '#fc1028',
		textDecoration: 'line-through',
	},
	currency: {

	},
	month: {

	},
	planButton: {
		background: '#D4D6D7',
		borderRadius: 30,
		textAlign: 'center',
		fontSize: 16,
		fontWeight: 700,
		color: '#ffffff',
		padding: '18px 10px',
		marginTop: 30,
	},
	planButtonUpgrade: {
		background: '#5058C8',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	planFreeOpenBtnContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		'& $planButton': {
			padding: '18px 30px',
			cursor: 'pointer',
			margin: 0,
		}
	},
	planButtonLoading: {
		background: '#D4D6D7',
		cursor: 'not-allowed',
	},
	planBottomInfo: {
		left: 0,
		right: 0,
		bottom: '-80px',
		position: 'absolute',
		fontSize: 13,
		fontWeight: 700,
		color:'#ffffff',
		padding: '15px 20px',
		backgroundColor: '#858ADA',
		borderRadius: 10,
		textAlign: 'center',
	},
	planBottomInfoDe: {
		bottom: '-100px',
	},
	planFooterFlag: {
		position: 'relative',
		fontSize: 11,
		fontWeight: 700,
		lineHeight: '12px',
		color: '#525B60',
		padding: '5px 15px 5px 30px',
		textAlign: 'center',
		marginTop: 25,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: '-1px',
			left: 0,
			width: 23,
			height: 22,
			backgroundSize: 'cover',
			backgroundImage: `url(${iconMoneyBack})`,
		},
	},
	planFeatureContainer: {
		margin: '0 10px',
		display: 'inline-block',
		textAlign: 'left',
	},
	planFeature: {
		position: 'relative',
		paddingLeft: 20,
		fontSize: 13,
		lineHeight: '28px',
		color: '#525B60',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
		margin: 0,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 5,
			left: 0,
			width: 16,
			height: 16,
			backgroundImage: `url(${checkMarkGreen})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
		'&$planFeaturesDeleteIcon': {
			'&::before': {
				content: '""',
				width: 14,
				height: 14,
				top: 5,
				backgroundImage: `url(${deleteRed})`,
			},
		},
		'&$planFeaturesExclamationIcon': {
			'&::before': {
				content: '""',
				width: 14,
				height: 14,
				top: 5,
				backgroundImage: `url(${exclamation})`,
			},
		},
	},
	planFeatureIcons: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		'& img': {
			marginLeft: 7,
		}
	},
	planFeaturesDeleteIcon: {},
	planFeaturesExclamationIcon: {},
	bestValuePackage: {
		'& $planOldPrice': {
			marginBottom: 10,
		}
	},
	freePackage: {
		'& $planPriceWrapper': {

		},
	},

	packageShort: {
		'&$packageContainer': {
			maxWidth: '360px !important',
		},
		'& $cardHeader': {
			'& span': {
				color: '#525B60',
				fontSize: 24,
			}
		},
		'& $card': {
			padding: '30px 20px 30px',
		},
		'& $planFeature': {
			color: '#525B60',
		},
	},
	activePackage: {
		'& $card': {
			minWidth: 'calc(100% - 8px)',
			backgroundColor: '#ffffff',
			border: '4px solid #5058c8',
			boxShadow: '0px 13px 32px rgba(109, 113, 115, 0.35)',
			transition: 'all 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		},
		'& $cardHeader': {
			'& span': {
				color: '#283339',
			}
		},
		'& $planPrice': {
			color: '#283339',
		},
		'& $planFeature': {
			color: '#283339',
		},
		'& $planButton': {
			background: '#FF8F00',
		},
		'& $planFooterFlag': {
			color: '#283339',
		},
	},
	upgradeMode: {
		'&$freePackage': {
			'& $card': {
				cursor: 'initial',
			}
		}
	},
	plansSeparator: {
		borderTop: '1px solid #E6E5E5',
		marginTop: 30,
		marginBottom: 30,
	},
	btnsContainer: {
		paddingBottom: 20,
	},
	btnOrange: {
		margin: 5,
		fontSize: 15,
		fontWeight: '700',
		lineHeight: '17px',
		color: '#ffffff',
		background: '#FF8F00',
		boxShadow: '0px 0px 6px rgba(40, 51, 57, 0.3)',
		borderRadius: 50,
		padding: '15px 30px',
		minWidth: 150,
		textAlign: 'center',
		textDecoration: 'none',
	},
	btnTransparent: {
		margin: 5,
		fontSize: 15,
		fontWeight: '700',
		lineHeight: '17px',
		color: '#93999C',
		borderRadius: 50,
		padding: '15px 30px',
		minWidth: 150,
		textAlign: 'center',
		textDecoration: 'none',
	},

	'@media (max-width: 959px)': {
		planBottomInfo: {
			bottom: '-90px',
			'& br': {
				display: 'none',
			},
		},

		planBottomInfoDe: {
			bottom: '-100px',
		},
		cardHeader: {
			display: 'block',
			'&>div': {
				display: 'block',
			}
		},
		planPrice: {
			fontSize: 48,
		},
		bestValuePackage: {
			order: '0 !important',
			'& $planFeature': {
				marginBottom: 0,
			},
		},
		freePackage : {
			order: '10 !important',
		},
	},
	'@media (max-width: 800px)': {
		interstitialStepLine: {
			display: 'none',
		},

		topBannerContainer: {
			flexDirection: 'column',
		},
		topBannerText: {
			textAlign: 'center',
		},
		topBannerCTA: {
			marginLeft: 0,
		},
	},
	'@media (max-width: 460px)': {
		interstitialSteps: {
			display: 'none',
		},
		contentHeader: {
			fontSize: 30,
			lineHeight: '30px',
		},
		pageWrapper : {
			padding: '20px 10px',
		},
		pageHeader: {
			padding: theme.spacing(6, 0, 1),
			'& h1': {
				fontSize: 28,
				lineHeight: '40px',
			}
		},
		packagesWrapper: {
			paddingBottom: 55,
		},
		card: {
			padding: '50px 15px 30px',
		},
		bestValuePackage: {
			marginTop: 12,
		},
		btnsContainer: {
			flexDirection: 'column',
		}
	}
});
import React, { Component } from 'react';
import { PlanTypes, Saving } from '../../config/plan-types';
import { Container, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import { withNamespaces } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './HomeV23Styles';
import { getCouponMonthly, getCouponYearly2, resetCouponByCode } from '../../store/actions';
import utils from '../../services/utils';
import clsx from 'clsx';
import { CURRENCY } from '../../config/main';

import Loader from '../../components/UI/Loader/Loader';
import Blocked from '../../components/UI/Blocked/Blocked';
import UserAvatar from '../../components/UI/svg/UserAvatar';
import PlansSectionV2 from '../../components/PlansSection/PlansSection';
import PaymentSectionV3 from '../../components/V3/PaymentSectionV3/PaymentSectionV3';
import AbandonmentModal from '../../components/AbandonmentModal/AbandonmentModalV2';
import ProxyDetectModal from '../../components/ProxyDetectModal/ProxyDetectModal';
import AimtellBanner from '../../components/AimtellBanner/AimtellBanner';
import SaleTimerBanner from '../../components/SaleTimerBanner/SaleTimerBanner';

const { REACT_APP_URL } = process.env;

class HomeV23 extends Component {
	constructor(props) {
		super(props);
		this.handleSelectPackage = this.handleSelectPackage.bind(this);
		this.handleScrollToPaymentSection = this.handleScrollToPaymentSection.bind(this);
		this.handleOpenAbandonmentModal = this.handleOpenAbandonmentModal.bind(this);
		this.handleCloseAbandonmentModal = this.handleCloseAbandonmentModal.bind(this);
		this.handleRefreshPage = this.handleRefreshPage.bind(this);
		this.initPackage = this.initPackage.bind(this);

		this.state = {
			showAbandonmentModal : false,
			showFreemiumModal: false,
			freemiumInterstitialStep: 0
		};
	}

	componentDidMount() {
		const {planConfig = {}, queryParams, couponMonthly, couponYearly2, onGetCouponMonthly, onGetCouponYearly2} = this.props;
		const _couponMonthly = queryParams.monthcoupon || planConfig?.couponMonthly || sessionStorage?.getItem('queryМonthCoupon');
		const _couponYearly2 = queryParams.biannualcoupon || planConfig?.couponYearly2 || sessionStorage?.getItem('queryBiannualCoupon');
		
		this.initPackage(this.props.packagesList);

		if(!couponMonthly.code && _couponMonthly) {
			sessionStorage?.setItem('queryМonthCoupon', _couponMonthly);
			onGetCouponMonthly(_couponMonthly, PlanTypes.MONTHLY, []); 
		}

		if(!couponYearly2.code && _couponYearly2) {
			sessionStorage?.setItem('queryBiannualCoupon', _couponYearly2);
			onGetCouponYearly2(_couponYearly2, PlanTypes.YEARLY2, (queryParams.antivirus  === 'true' ? ['SENTRYS'] : []));  
		}

		this.props.history.listen((location, action) => {
			if(action === 'POP') {
				this.initPackage(this.props.packagesList);
			}
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if(!this.props.packagesList.length && nextProps.packagesList.length) {
			this.initPackage(nextProps.packagesList);
		}
		if(nextProps.queryParams.plan) {
			this.handleScrollToPaymentSection();
		}
	}

	initPackage(packagesList = []) {
		const {planConfig = {}, queryParams, onSetSelectedPackage} = this.props;
		const _yearly2Package = packagesList.find(p => p.packageIdentifier === PlanTypes.YEARLY2) || {};
		let _selectedPackage = packagesList.find(p => p.packageIdentifier === PlanTypes.YEARLY) || {};

		if(planConfig.showYearly2 && _yearly2Package.packageIdentifier) {
			_selectedPackage = _yearly2Package;
		}
		if(queryParams.plan) {
			const _redirectedPackage = packagesList.find(p => p.packageIdentifier === queryParams.plan) || {};
			if(_redirectedPackage.packageIdentifier
				&& (!planConfig.hideFree || planConfig.hideFree && _redirectedPackage.packageIdentifier !== PlanTypes.FREE)
				&& (!planConfig.showFreeBtn || planConfig.showFreeBtn && _redirectedPackage.packageIdentifier !== PlanTypes.FREE)
				&& (!planConfig.hideMonthly || planConfig.hideMonthly && _redirectedPackage.packageIdentifier !== PlanTypes.MONTHLY)
				&& (!planConfig.hideYearly || planConfig.hideYearly && _redirectedPackage.packageIdentifier !== PlanTypes.YEARLY)) {
				_selectedPackage = _redirectedPackage;
			}
		}
		onSetSelectedPackage(_selectedPackage);
	}

	handleSelectPackage(p) {
		this.props.onSetSelectedPackage(p);
	}

	handleScrollToPaymentSection(p) {
		if(p) {
			this.props.onSetSelectedPackage(p);
		}
		setTimeout(() => {
			scroller.scrollTo('paymentSection', {
				duration: 700,
				smooth: true
			});
		}, 200);
	}

	handleOpenAbandonmentModal() {
		this.setState({showAbandonmentModal: true}, () => {
			document.body.classList.add('overflow-hidden');
		});
	}

	handleCloseAbandonmentModal(e, plan) {
		e.stopPropagation();
		this.setState({showAbandonmentModal: false}, () => {
			document.body.classList.remove('overflow-hidden');
			if(plan) {
				this.handleScrollToPaymentSection();
				this.props.onSetSelectedPackage(plan);
			}
		});
	}

	handleRefreshPage() {
		this.props.history.go(0);
	}

	render() {
		const {
			t,
			history,
			classes,
			paymentJS,
			selectedPackage,
			packagesList,
			securityDetails: {current_currency, is_proxy_detected, is_blocked},
			queryParams,
			queryParams: { 
				current_plan, 
				username,
				email,
				active_vpn, 
				days_left_qty, 
				userstatus,
				token
			},
			coupon,
			couponByCode,
			planConfig = {},
			enableAbandonmentModal,
			serversDetails,
			disableProxyPopup,
			upgradeMode,
			couponMonthly,
			couponYearly2,
			loading
		} = this.props;
		const {showAbandonmentModal, showFreemiumModal} = this.state;
		let _packagesList = packagesList.map(p => {
			return {
				...p,
				recurringPeriod: {
					...p.recurringPeriod,
					basePrice: [...p.recurringPeriod.basePrice
					]
				}

			};
		});
		
		if(couponYearly2.code) {
			const couponYearly2ValidPlan = couponYearly2.validPlans && couponYearly2.validPlans[0] || '';
			const couponYearly2PriceObj = couponYearly2.typeParameters && couponYearly2.typeParameters.fixedPrice || {};

			_packagesList =  _packagesList.map(p => {
				let _bp = p.recurringPeriod && p.recurringPeriod.basePrice;
				if(couponYearly2ValidPlan ===  p.packageIdentifier && _bp.length) {
					p.recurringPeriod.basePrice = _bp.map(bp => ({...bp, amount: +(couponYearly2PriceObj[bp.currencyCode] || bp.amount)}));
					p.recurringPeriod.renewalPrice = +couponYearly2.renewal_price || 0;
					if(couponYearly2.grace_period_months) {
						p.recurringPeriod.grace_period_months = couponYearly2.grace_period_months;
					}
				}
				return p;
			});
		}

		if(couponMonthly.code) {
			const couponMonthlyValidPlan = couponMonthly.validPlans && couponMonthly.validPlans[0] || '';
			const couponMonthlyPriceObj = couponMonthly.typeParameters && couponMonthly.typeParameters.fixedPrice || {};

			_packagesList =  _packagesList.map(p => {
				let _bp = p.recurringPeriod && p.recurringPeriod.basePrice;
				if(couponMonthlyValidPlan ===  p.packageIdentifier && _bp.length) {
					p.recurringPeriod.basePrice = _bp.map(bp => ({...bp, amount: +(couponMonthlyPriceObj[bp.currencyCode] || bp.amount)}));
				}
				return p;
			});
		}

		const packages = _packagesList.filter(p => {
			return (p.packageIdentifier !== PlanTypes.OVERQUOTA 
				&& (p.packageIdentifier !== PlanTypes.YEARLY2 || p.packageIdentifier == PlanTypes.YEARLY2 && (planConfig.showYearly2 || upgradeMode)) 
				&& (planConfig.showOnlyYearly ? (p.packageIdentifier === PlanTypes.YEARLY) : (
					(
						(planConfig.hideFree && p.packageIdentifier !== PlanTypes.FREE) || !planConfig.hideFree) && 
						((planConfig.hideMonthly && p.packageIdentifier !== PlanTypes.MONTHLY) || !planConfig.hideMonthly) &&
						((planConfig.hideYearly && p.packageIdentifier !== PlanTypes.YEARLY) || !planConfig.hideYearly)
				)));
		});

		const freePackage = _packagesList.find(p => p.packageIdentifier === PlanTypes.FREE) || {};
		const monthlyPackage = _packagesList.find(p => p.packageIdentifier === PlanTypes.MONTHLY) || {};
		const monthlyPrice = monthlyPackage.recurringPeriod?.basePrice.find(bp => bp.currencyCode === current_currency)?.amount || 0;
		const yearlyPackage = _packagesList.find(p => p.packageIdentifier === PlanTypes.YEARLY) || {};
		const yearlyPrice = yearlyPackage.recurringPeriod?.basePrice.find(bp => bp.currencyCode === current_currency)?.amount || 0;
		const yearly2Package = _packagesList.find(p => p.packageIdentifier === PlanTypes.YEARLY2) || {};
		const yearly2Price = yearly2Package.recurringPeriod?.basePrice.find(bp => bp.currencyCode === current_currency)?.amount || 0;
		const yearly2GracePeriod = yearly2Package.recurringPeriod?.grace_period_months || 0;
		const _coupon = couponByCode.code ? couponByCode : coupon;
		const couponValidPlan = _coupon.validPlans && _coupon.validPlans[0] || '';
		const couponGracePeriod = _coupon.grace_period_months || 0;
		const couponPrice = _coupon.typeParameters && _coupon.typeParameters.fixedPrice && _coupon.typeParameters.fixedPrice[current_currency] || '';
		const couponRenewalPrice = +(_coupon.renewal_price || 0);
		const couponSavingPercent = _coupon.discount_percent ? (_coupon.discount_percent + '%') : Saving.percent;
		const calculatePricePerMonth = () => {
			let _pricePerMonth = 0;
	
			if(planConfig.showYearly2 || (upgradeMode && (userstatus === 'yearly' || userstatus === 'biannually'))) {
				_pricePerMonth = (couponPrice && couponValidPlan === PlanTypes.YEARLY2) ? couponPrice/(couponGracePeriod || 24) : yearly2Price/(yearly2GracePeriod || 24);
			} else {
				_pricePerMonth = (couponPrice && couponValidPlan === PlanTypes.YEARLY) ? couponPrice/(couponGracePeriod || 12) : yearlyPrice/12;
			}
	
			return (Math.round(_pricePerMonth*100)/100).toFixed(2);
		};
		const pricePerMonth = (CURRENCY[current_currency] || current_currency) + calculatePricePerMonth();
		const saving = selectedPackage.packageIdentifier ? ((couponValidPlan === PlanTypes.MONTHLY && couponPrice) || monthlyPrice)*(selectedPackage.packageIdentifier === PlanTypes.YEARLY && (couponGracePeriod || 12) || selectedPackage.packageIdentifier === PlanTypes.YEARLY2 && (yearly2GracePeriod || 24)) - (selectedPackage.packageIdentifier === PlanTypes.YEARLY ? yearlyPrice : selectedPackage.packageIdentifier === PlanTypes.YEARLY2 ? yearly2Price : 0) : 0;
		const savingPercent = utils.calculateSavingPercent(yearlyPackage, monthlyPrice, PlanTypes, current_currency, _coupon);
		const saving2Percent = utils.calculateSavingPercent(yearly2Package, monthlyPrice, PlanTypes, current_currency, _coupon);

		return (
			<>
				{loading ? <Loader /> : 
					is_blocked ? <Blocked /> :
						<div className="page-content" id="page-content" style={{display: (loading ? 'none' : 'block')}}>
							{token ?
								<Box className={classes.userBarWrapper}>
									<Container maxWidth="lg" className={classes.userBarContainer}>
										<Box className={classes.userBartem}>
											<UserAvatar /> {username}
										</Box>
										{username ? <Box className={classes.userBarSeparator} /> : null}
										<Box className={classes.userBartem}>{email}</Box>
										{email ? <Box className={classes.userBarSeparator} /> : null}
										<Box className={classes.userBartem}>{current_plan}</Box>
									</Container>
								</Box>: null
							}

							{!planConfig.hideTopBanner && upgradeMode && (!active_vpn && current_plan == 'FREE') ?
								<div className={classes.topBanner} onClick={this.handleScrollToPaymentSection}>
									<Container component="div" className={classes.topBannerContainer}>
										<Typography component="p" className={classes.topBannerText}>
											{t((days_left_qty >= 0) ?
												'You have 0 GB of data remaining. To stay connected and secure, activate Limited Speed or upgrade to get unlimited data.' :
												'Your account is eligible for more monthly data. Refresh now for an extra 10 GB or upgrade to get unlimited VPN data and protection.'
											)}
										</Typography>
										<Typography component="div" className={classes.topBannerCTA}>
											{t('Upgrade Now')}
										</Typography>
									</Container>
								</div>: null
							}
							{planConfig.aimtellBanner ?
								<div className={classes.bannerWrapper}>
									<AimtellBanner 
										price={pricePerMonth} 
										onCLickBanner={() => this.handleScrollToPaymentSection(planConfig.showYearly2 ? yearly2Package : yearlyPackage)} />
								</div> : null
							}
							{planConfig.saleTimerBanner ?
								<div className={classes.bannerWrapper}>
									<SaleTimerBanner 
										price={pricePerMonth} 
										onCLickBanner={() => this.handleScrollToPaymentSection(planConfig.showYearly2 ? yearly2Package : yearlyPackage)}  />
								</div> : null
							}

							<Container maxWidth="md" component="main" className={classes.pageWrapper}>
								<Typography component="h1" variant="h4" align={planConfig.title ? 'center' : 'left'} color="textPrimary" gutterBottom className={clsx(classes.contentHeader, {[classes.withSubtitle]: planConfig.subTitle})}>
									{planConfig.title ? 
										utils.processTitle(t, current_currency, (planConfig.showYearly2 ? Math.round((yearly2Price/(yearly2GracePeriod || 24))*100)/100 : ((couponValidPlan === PlanTypes.YEARLY && couponPrice ? Math.round((couponPrice/(couponGracePeriod || 12))*100)/100 : Math.round((yearlyPrice/12)*100)/100).toFixed(2))), planConfig.title) : 
										t('Get PrivadoVPN')
									}
								</Typography>

								{planConfig.subTitle ?
									planConfig.subTitle.split('{br}').map((st, i) => {
										return (
											st.length ?
												<Typography key={i} component="h2" align="center" color="textPrimary" className={classes.subTitle} style={{maxWidth: (planConfig.subTitleWidth ? (planConfig.subTitleWidth + 'px') : '100%')}} gutterBottom>
													{utils.processTitle(t, current_currency, (planConfig.showYearly2 ? Math.round((yearly2Price/(yearly2GracePeriod || 24))*100)/100 : ((couponValidPlan === PlanTypes.YEARLY && couponPrice ? Math.round((couponPrice/(couponGracePeriod || 12))*100)/100 : Math.round((yearlyPrice/12)*100)/100).toFixed(2))), planConfig.subTitle)}
												</Typography>: null
										);
									}) :
									<Typography component="h2" align="center" color="textPrimary" gutterBottom>
										{t('PrivadoVPN offers a Zero-Log server network protected by Swiss-privacy laws.')}
									</Typography>
								}

								<PlansSectionV2
									history={history}
									upgradeMode={upgradeMode}
									planConfig={planConfig}
									savingPercent={savingPercent}
									saving2Percent={saving2Percent}
									couponPrice={couponPrice}
									couponGracePeriod={couponGracePeriod}
									couponValidPlan={couponValidPlan}
									couponSavingPercent={couponSavingPercent}
									couponRenewalPrice={couponRenewalPrice}
									queryParams={queryParams}
									packages={packages}
									monthlyPrice={monthlyPrice}
									yearlyPackage={yearlyPackage}
									yearly2Package={yearly2Package}
									classes={classes}
									currency={current_currency}
									selectedPackage={selectedPackage}
									serversDetails={serversDetails}
									showFreemiumModal={showFreemiumModal}
									handleSelectPackage={this.handleSelectPackage}
									handleScrollToPaymentSection={this.handleScrollToPaymentSection} />
								
								<PaymentSectionV3
									upgradeMode={upgradeMode}
									history={history}
									planConfig={planConfig}
									packages={packages}
									savingPercent={savingPercent}
									saving2Percent={saving2Percent}
									coupon={_coupon}
									couponPrice={couponPrice}
									couponGracePeriod={couponGracePeriod}
									couponValidPlan={couponValidPlan}
									couponSavingPercent={couponSavingPercent}
									couponRenewalPrice={couponRenewalPrice}
									queryParams={queryParams}
									currency={current_currency}
									paymentJS={paymentJS}
									saving={Math.round(saving * 100) / 100}
									selectedPackage={packages.filter( p => p.packageIdentifier === selectedPackage.packageIdentifier)[0] || {}}
									yearlyPackage={yearlyPackage}
									hideCouponSection={true}
									handleSelectPackage={this.handleSelectPackage}
									enableAbandonmentModal={enableAbandonmentModal}
									onOpenAbandonmentModal={this.handleOpenAbandonmentModal}
									handleScrollToPaymentSection={this.handleScrollToPaymentSection} />
							</Container>

							{(disableProxyPopup || !is_proxy_detected) && !showFreemiumModal && enableAbandonmentModal ?
								<AbandonmentModal
									customTitle={planConfig.modalTitle}
									showYearly2={planConfig.showYearly2}
									showModal={showAbandonmentModal}
									paid={true}
									couponPrice={couponPrice}
									couponGracePeriod={couponGracePeriod}
									couponValidPlan={couponValidPlan}
									couponSavingPercent={couponSavingPercent}
									savingPercent={savingPercent}
									saving2Percent={saving2Percent}
									freePackage={freePackage}
									yearlyPackage={yearlyPackage}
									yearly2Package={yearly2Package}
									currency={current_currency}
									onClose={this.handleCloseAbandonmentModal} /> : null
							}
							{!disableProxyPopup && is_proxy_detected ?
								<ProxyDetectModal
									onClick={this.handleRefreshPage} /> :
								null
							}
							
							<Container maxWidth="md" component="main">
								<Box display="flex" justifyContent="center" className={classes.btnsContainer}>
									<a color="textPrimary" href={REACT_APP_URL + '/cancel/verify'} rel="noopener noreferrer" className={classes.btnTransparent}>
										{t('Continue Cancellation')}
									</a>
									<a color="textPrimary" href={REACT_APP_URL + '/admin-panel'} rel="noopener noreferrer" className={classes.btnOrange}>
										{t('Keep My Account')}
									</a>
								</Box>
							</Container>
						</div>	
				}
				<span id="maskEmailField"></span>
			</>
		);
	}

}

const mapStateToProps = (state) => {
	const {couponIsLoading, couponYearly2IsLoading, couponMonthlyIsLoading, userAuthInfoLoading} = state.home;
	const {packagesIsLoading} = state.packages;
	const {checkingSecurity} = state.securityCheck;

	return {
		packagesList: state.packages.packagesList,
		selectedPackage: state.home.selectedPackage || {},
		securityDetails: state.securityCheck.securityDetails,
		coupon: state.home.coupon,
		couponByCode: state.home.couponByCode,
		serversDetails: state.home.serversDetails,
		couponMonthly: state.home.couponMonthly,
		couponYearly2: state.home.couponYearly2,
		sentryProps: state.home.sentryProps,
		loading: checkingSecurity || packagesIsLoading || couponIsLoading || couponYearly2IsLoading || couponMonthlyIsLoading || userAuthInfoLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetCouponMonthly: (coupon, plan, addons) => dispatch(getCouponMonthly(coupon, plan, addons)),
		onGetCouponYearly2: (coupon, plan, addons) => dispatch(getCouponYearly2(coupon, plan, addons)),
		onResetCouponByCode: dataToSend => dispatch(resetCouponByCode(dataToSend)),
	};
};

HomeV23.propTypes = {
	sentryProps: PropTypes.object,
	upgradeMode: PropTypes.bool,
	history: PropTypes.object,
	coupon: PropTypes.object,
	couponMonthly: PropTypes.object,
	couponYearly2: PropTypes.object,
	couponByCode: PropTypes.object,
	serversDetails: PropTypes.object,
	planConfig: PropTypes.object,
	classes: PropTypes.object,
	queryParams: PropTypes.object,
	paymentJS: PropTypes.object,
	selectedPackage: PropTypes.object,
	securityDetails: PropTypes.object,
	packagesList: PropTypes.array,
	loading: PropTypes.bool,
	enableAbandonmentFreeModal: PropTypes.bool,
	enableAbandonmentModal: PropTypes.bool,
	disableProxyPopup: PropTypes.bool,
	onSetSelectedPackage: PropTypes.func,
	onGetCouponMonthly: PropTypes.func,
	onGetCouponYearly2: PropTypes.func,
	onResetCouponByCode: PropTypes.func,
	t: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withNamespaces()(HomeV23)));
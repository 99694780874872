import React, { useState, useEffect } from 'react';
import { PlanTypes } from '../../config/plan-types';
import { CURRENCY } from '../../config/main';
import { connect } from 'react-redux';
import { reactivateFreemium, getCoupon, getCouponByCode, updateSentryProps, resetCouponByCode } from '../../store/actions';
import LoaderSmall from '../UI/LoaderSmall/LoaderSmall';
import PropTypes from 'prop-types';
import clsx  from 'clsx';
import { withNamespaces, Trans } from 'react-i18next';
import utils from '../../services/utils';
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	Typography,
	Container,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import {animateScroll} from 'react-scroll';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './PlansSectionStyles';
import osIconsBlack from '../../assets/images/icons-os-black.png';
import osIconsGrey from '../../assets/images/icons-os-grey.png';
import FreemiumModal from '../../components/FreemiumModal/FreemiumModal';

const { REACT_APP_URL } = process.env;

function PlansSection({
	t,
	i18n,
	navigate,
	planConfig,
	savingPercent,
	saving2Percent,
	packages,
	monthlyPrice,
	yearlyPackage,
	yearly2Package,
	classes,
	currency,
	selectedPackage,
	handleSelectPackage,
	handleScrollToPaymentSection,
	serversDetails,
	couponByCode,
	couponPrice,
	couponGracePeriod,
	couponValidPlan,
	couponSavingPercent,
	couponRenewalPrice,
	sentryProps,
	queryParams: { 
		userstatus, 
		username, 
		previous_plan,
		current_plan, 
		active_vpn, 
		days_left_qty,
		enable_free_premiums_campaign,
		trial_period_started
	},
	upgradeMode,
	showFreemiumModal,
	onReactivateFreemium,
	onGetCoupon,
	onGetCouponByCode,
	onResetCouponByCode,
	onToggleFreemiumModal,
	onUpdateSentryProps
}) {
	useEffect(() => {
		animateScroll.scrollToTop({
			duration: 400,
			smooth: true
		});
	}, []);

	const [btnProcessing, updateBtnProcessing] = useState(false);
	const yearly2Price = yearly2Package?.recurringPeriod?.basePrice.find(bp => bp.currencyCode === currency)?.amount || 0;
	const yearly2GracePeriod = yearly2Package?.recurringPeriod?.grace_period_months || 0;
	const _freemiumModalPrice = (planConfig.showYearly2 ? Math.round((yearly2Price/(yearly2GracePeriod || 24))*100)/100 : 2.5).toFixed(2);
	const _freemiumModalPercent = planConfig.showYearly2 ? saving2Percent : savingPercent;
	
	const handleClickSKU = (e, p, scrollEnable) => {
		const dataLayer = window.dataLayer || [];
		const _event = p.packageIdentifier === PlanTypes.FREE ? 'selectFree' : p.packageIdentifier === PlanTypes.MONTHLY ? 'selectMonthly' : (p.packageIdentifier === PlanTypes.YEARLY || p.packageIdentifier === PlanTypes.YEARLY2) ? 'selectAnnual' : '';
		const _location = upgradeMode ? 'upgrade' : 'checkout';
		dataLayer.push({
			'event': _event,
			'location': _location,
		});

		if(upgradeMode && p.packageIdentifier === PlanTypes.FREE) {
			return;
		}
		e.stopPropagation();
		handleSelectPackage(p);
		if(upgradeMode || scrollEnable || selectedPackage.packageIdentifier === p.packageIdentifier) {
			handleScrollToPaymentSection();
		}
	};

	const handleUpdateSentryProps = (checked, packageId) => {
		const _couponByCode = couponByCode.code || couponByCode.prevCode;
		onUpdateSentryProps({
			...sentryProps,
			[packageId]: checked
		});

		if(_couponByCode && packageId) {
			if(checked) {
				onGetCouponByCode(_couponByCode, packageId, (packageId ? ['SENTRYS'] : []));
			} else {
				onResetCouponByCode(couponByCode.code);
			}
		}
	};

	const handleReactivateFreemium = () => {
		updateBtnProcessing(true);
		if(!btnProcessing) {
			onReactivateFreemium(username)
				.then(response => {
					if(response.error) {
						updateBtnProcessing(false);
					} else {
						window.location.href = REACT_APP_URL + '/' + i18n.resolvedLanguage + '/admin-panel?inapp=1';
					}
				});
		}
	};

	const handleOpenFreemiumModal = () => {
		onToggleFreemiumModal(true);
		document.body.style.overflow = 'hidden';
	};

	const handleCloseFreemiumPopup = (e, selectPlan, redirect) => {
		e.stopPropagation();
		onToggleFreemiumModal(false);
		document.body.style.overflow = '';
		if(selectPlan) {
			handleSelectPackage(planConfig.showYearly2 ? yearly2Package : yearlyPackage);
			handleScrollToPaymentSection();
			if(!planConfig.showYearly2)  {
				onGetCoupon({
					coupon_code: 'additionaloptions30',
					disableLoading: true,
					package_id: PlanTypes.YEARLY,
					addons: (sentryProps[PlanTypes.YEARLY] ? ['SENTRYS'] : [])
				});
			}
		}
		if(redirect) {
			navigate((i18n.resolvedLanguage === 'en' ? '' : '/' + i18n.resolvedLanguage) + '/free');
			handleSelectPackage(yearlyPackage);
			onGetCoupon({
				coupon_code: 'riskfree30',
				disableLoading: true,
				package_id: PlanTypes.YEARLY,
				addons: (sentryProps[PlanTypes.YEARLY] ? ['SENTRYS'] : [])
			});
		}
	};

	const handleOrderSKU = (p, i) => {
		switch (p.packageIdentifier) {
		case PlanTypes.FREE:
			return planConfig.freeOrder || 1;
		case PlanTypes.YEARLY:
			return planConfig.yearlyOrder || (planConfig.showYearly2 ? 1 : 2);
		case PlanTypes.YEARLY2:
			return planConfig.yearly2Order || 2;
		case PlanTypes.MONTHLY:
			return planConfig.monthlyOrder || (planConfig.hideFree && !planConfig.showYearly2 ? 1 : i+4);
		default:
			return i + 4;
		}
	};

	const _renderPlans = () => {
		if(planConfig.showShortPlans) {
			return (
				<Grid container spacing={5} justify="center">
					{packages.map((p, i) => {
						const _currency = CURRENCY[currency] || currency;
						const yearlyPrice = yearlyPackage.recurringPeriod?.basePrice.filter(bp => bp.currencyCode === currency)[0]?.amount || 0;
						const pricePerMonth = ((couponPrice && couponValidPlan === PlanTypes.YEARLY) ? couponPrice/(couponGracePeriod || 12) : yearlyPrice/12 || 0).toFixed(2);
						const transCitiesCount = p.packageIdentifier === PlanTypes.FREE ? (serversDetails.free_cities_count || 12) : (serversDetails.cities_count || 58);

						return (
							<Grid item key={p.packageIdentifier} xs={12} sm={12} md={4}
								onClick={(e) => handleClickSKU(e, p)}
								style={{order: handleOrderSKU(p, i)}}
								className={clsx(classes.packageContainer, classes.packageShort, {
									[classes.bestValuePackage]: (p.packageIdentifier === PlanTypes.YEARLY),
									[classes.freePackage]: (p.packageIdentifier === PlanTypes.FREE),
									[classes.activePackage]: (p.packageIdentifier === selectedPackage.packageIdentifier),
									[classes.hide]: (upgradeMode && (userstatus === 'monthly') && (p.packageIdentifier === PlanTypes.FREE || p.packageIdentifier === PlanTypes.MONTHLY))
								})}
							>
								<Card classes={{root: classes.card}} className={clsx({[classes.planShowBill]: planConfig.planShowBill})}>
									<CardHeader
										title={(p.packageIdentifier === PlanTypes.FREE) ? t('10GB  Data Limit') : (p.packageIdentifier === PlanTypes.YEARLY) ? t('30 Days Risk-Free') : p.displayNames[0].name}
										titleTypographyProps={{ align: 'center' }}
										className={classes.cardHeader}
									/>
									<CardContent className={classes.cardContent}>
										<div className={classes.planPriceWrapper}>
											<div className={clsx(classes.planPrice, classes.planPriceShort)}>
												<span>{_currency + ((p.packageIdentifier === PlanTypes.YEARLY) ? pricePerMonth : '0.00')}</span>
												{(p.packageIdentifier === PlanTypes.YEARLY) ? <span>{t('/mo')}</span> : null}
											</div>
											<ul className={classes.planFeatureContainer}>
												<li className={clsx(classes.planFeature, {[classes.planFeaturesExclamationIcon]: (p.packageIdentifier === PlanTypes.FREE)})}>
													{t(p.packageIdentifier === PlanTypes.FREE ? '10GB Data Every 30-Days' : 'Unlimited Data')}
												</li>
												<li className={clsx(classes.planFeature, {[classes.planFeaturesExclamationIcon]: (p.packageIdentifier === PlanTypes.FREE)})}>
													<Trans i18nKey="transCitiesCount" transCitiesCount={transCitiesCount}>
														Servers in {{transCitiesCount}} Cities
													</Trans>
												</li>
												<li className={clsx(classes.planFeature, {[classes.planFeaturesExclamationIcon]: (p.packageIdentifier === PlanTypes.FREE)})}>
													{t(p.packageIdentifier === PlanTypes.FREE ? '1 Connection' : '10 Connections')}
												</li>
												<li className={clsx(classes.planFeature, {[classes.planFeaturesDeleteIcon]: (p.packageIdentifier === PlanTypes.FREE)})}>
													{t('Ad Blocker')}
												</li>
											</ul>
											<div className={classes.planButton}>{t('Select')}</div>
										</div>
									</CardContent>
									{(p.packageIdentifier === PlanTypes.YEARLY && !planConfig.hideYearlyMBG || p.packageIdentifier === PlanTypes.YEARLY2) ?
										<div className={classes.planFooterFlag}>
											{t('30-Day Money Back Guarantee')}
										</div> : null
									}
								</Card>
							</Grid>
						);
					})}
				</Grid>
			);
		}
		return (
			<Grid container spacing={5} justify="center">
				{packages.map((p, i) => {
					const _currency = CURRENCY[currency] || currency;
					const _basePrice = (p?.recurringPeriod?.basePrice || []).find(b => b.currencyCode === currency);
					const _amount = _basePrice?.amount || 0;
					const _gracePeriod = p?.recurringPeriod?.grace_period_months || 0;
					const _renewalPrice = p?.recurringPeriod?.renewalPrice || 0;
					const _baseSentryPrice = ((p?.addonPackages || []).find(a => a.packageIdentifier.indexOf('SENTRY') > -1)?.recurringPeriod?.basePrice || []).find(b => b.currencyCode === currency);
					const _amountSentry = _baseSentryPrice?.amount || 0;

					const transCitiesCount = p.packageIdentifier === PlanTypes.FREE ? (serversDetails.free_cities_count || 12) : (serversDetails.cities_count || 58);
					const transMessageCitiesCount = serversDetails.cities_count || 58;
					
					return (
						<Grid item key={p.packageIdentifier} xs={12} sm={12} md={4}
							onClick={(e) => handleClickSKU(e, p)}
							style={{order: handleOrderSKU(p, i), marginBottom: (((p.packageIdentifier === PlanTypes.MONTHLY || p.packageIdentifier === PlanTypes.YEARLY) && p.packageIdentifier === selectedPackage.packageIdentifier) ? 80 : 0)}}
							className={clsx(classes.packageContainer, {
								[classes.bestValuePackage]: (p.packageIdentifier === PlanTypes.YEARLY),
								[classes.freePackage]: (p.packageIdentifier === PlanTypes.FREE),
								[classes.activePackage]: (p.packageIdentifier === selectedPackage.packageIdentifier),
								[classes.upgradeMode]: upgradeMode,
								[classes.hide]: (upgradeMode && (
									(p.packageIdentifier === PlanTypes.FREE && (active_vpn || previous_plan === PlanTypes.MONTHLY || previous_plan === PlanTypes.YEARLY || previous_plan === PlanTypes.YEARLY2)) || 
									(p.packageIdentifier === PlanTypes.FREE && (enable_free_premiums_campaign && trial_period_started && trial_period_started !== 'None')) || 
									((p.packageIdentifier === PlanTypes.FREE || p.packageIdentifier === PlanTypes.MONTHLY) && ((userstatus === 'monthly') || active_vpn && [PlanTypes.MONTHLY, PlanTypes.YEARLY, PlanTypes.YEARLY2, PlanTypes.YEARLYS, PlanTypes.YEARLY2S].indexOf(current_plan) > -1)) || 
									(p.packageIdentifier === PlanTypes.YEARLY2 && !active_vpn && (!current_plan && (previous_plan === PlanTypes.FREE || previous_plan === PlanTypes.OVERQUOTA) || current_plan === PlanTypes.FREE || current_plan === PlanTypes.OVERQUOTA)) && (!enable_free_premiums_campaign || enable_free_premiums_campaign && (!trial_period_started || trial_period_started === 'None'))
								))
							})}
						>
							<Card classes={{root: classes.card}} className={clsx({[classes.planShowBill]: planConfig.planShowBill || planConfig.planShowBillShort})}>
								{p.packageIdentifier === PlanTypes.YEARLY && !planConfig.hideYearlyBadge ? <div className={classes.cardHeaderBadge}>{t('Save') + ' ' + (savingPercent || planConfig.savingPercent || couponSavingPercent)}</div> : p.packageIdentifier === PlanTypes.YEARLY2 ? <div className={classes.cardHeaderBadge}>{t('Save') + ' ' + (saving2Percent || couponSavingPercent)}</div> : null}
								<CardHeader
									title={(p.packageIdentifier === PlanTypes.FREE) ? t('Free Plan') :
										(p.packageIdentifier === PlanTypes.YEARLY) ? planConfig.yearlySkuTitle ? utils.processTitle(t, _currency, (couponValidPlan === PlanTypes.YEARLY && couponPrice ? (couponGracePeriod && !planConfig.showSkuPricePerMonth ? +couponPrice : Math.round((couponPrice/(planConfig.showSkuPricePerMonth ? (couponGracePeriod || 12) : 12))*100)/100) : Math.round((_amount/12)*100)/100), planConfig.yearlySkuTitle) :
											((couponValidPlan === PlanTypes.YEARLY) ?
												(couponGracePeriod === 0 ? 
													t('12 Month Plan') :
													<Trans i18nKey="transSkuTitleMonthsExt">
														{{transSkuTitleMonth: 12}} Month Unlimited VPN + {{transSkuTitleGracePeriodLg: (couponGracePeriod - 12)}} FREE
													</Trans>):
												(_gracePeriod > 12) ?
													<Trans i18nKey="transSkuTitleMonthsExt">
														{{transSkuTitleMonth: 12}} Month Unlimited VPN + {{transSkuTitleGracePeriodLg: (_gracePeriod - 12)}} FREE
													</Trans>: t('12 Month Plan')) :
											(p.packageIdentifier === PlanTypes.YEARLY2) ? (couponValidPlan === PlanTypes.YEARLY2) ?
												(couponGracePeriod === 0 ? 
													t('24 Month Plan'): 
													<Trans i18nKey="transSkuTitleMonthsExt">
														{{transSkuTitleMonth: 24}} Month Unlimited VPN + {{transSkuTitleGracePeriodLg: (couponGracePeriod - 24)}} FREE
													</Trans>):
												(_gracePeriod > 24) ?
													<Trans i18nKey="transSkuTitleMonthsExt">
														{{transSkuTitleMonth: 24}} Month Unlimited VPN + {{transSkuTitleGracePeriodLg: (_gracePeriod - 24)}} FREE
													</Trans>: t('24 Month Plan') :
												(p.packageIdentifier === PlanTypes.MONTHLY) ? t('1 Month Plan') : p.displayNames[0].name}
									titleTypographyProps={{ align: 'center' }}
									className={classes.cardHeader}
								/>
								<CardContent className={classes.cardContent}>
									<div className={clsx(classes.planPriceWrapper, {[classes.pt10]: planConfig.showSkuSubtitle})}>
										{planConfig.showSkuSubtitle ? 
											<div className={classes.planOldPrice}>
												{p.packageIdentifier === PlanTypes.YEARLY2 ? 
													<span>{t('Reg. Price:')} <span className={classes.lineThrough}>{(CURRENCY[currency] || currency) + (couponValidPlan === PlanTypes.MONTHLY && couponPrice || monthlyPrice)*24}</span></span> : 
													null
												}
												{p.packageIdentifier === PlanTypes.YEARLY ?
													<span>{t('Reg. Price:')} <span className={classes.lineThrough}>{(CURRENCY[currency] || currency) + (couponValidPlan === PlanTypes.MONTHLY && couponPrice || monthlyPrice)*12}</span></span>
													: null
												}
											</div> : null
										}
										<div className={classes.planPrice}>
											<sup className={classes.currency}>{_currency}</sup>
											<span>
												{((p.packageIdentifier === PlanTypes.YEARLY ?
													(couponValidPlan === PlanTypes.YEARLY && couponPrice ? (couponGracePeriod && !planConfig.showSkuPricePerMonth ? +couponPrice : Math.round((+couponPrice/(planConfig.showSkuPricePerMonth ? (couponGracePeriod || 12) : 12))*100)/100) : Math.round((_amount/(_gracePeriod || 12))*100)/100) :
													p.packageIdentifier === PlanTypes.YEARLY2 ?
														(couponValidPlan === PlanTypes.YEARLY2 && couponPrice ? (couponGracePeriod && !planConfig.showSkuPricePerMonth ? +couponPrice : Math.round((+couponPrice/(planConfig.showSkuPricePerMonth ? (couponGracePeriod || 24) : 24))*100)/100) : Math.round((_amount/(_gracePeriod || 24))*100)/100) :
														p.packageIdentifier === PlanTypes.MONTHLY ? (couponValidPlan === PlanTypes.MONTHLY && couponPrice ? +couponPrice : _amount) : _amount)).toFixed(2)}
											</span>
											{(couponGracePeriod && !planConfig.showSkuPricePerMonth) || (planConfig.hideYearlyPerMonth && p.packageIdentifier === PlanTypes.YEARLY) ? null : <span className={classes.month}>{t('/mo')}</span>}
										</div>
										{planConfig.planShowBill ?
											<div className={classes.planOldPrice}>
												{p.packageIdentifier === PlanTypes.FREE ?
													t('Get 10GB of data every 30-days.') :
													null
												}

												{p.packageIdentifier === PlanTypes.YEARLY ? 
													(couponValidPlan === PlanTypes.YEARLY) ?
														(couponGracePeriod || couponRenewalPrice) ?
															<Trans i18nKey="transBilledGracePeriodMonths">
																Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+couponPrice).toFixed(2)}} for the first {{trans24GracePeriod: couponGracePeriod || 12}} months, then {{transBilledEvery12Months2: (CURRENCY[currency] || currency) + (+couponRenewalPrice).toFixed(2)}} every 12 months.
															</Trans>:
															<Trans i18nKey="transBilledEvery12MonthsAlways">
																Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+couponRenewalPrice).toFixed(2)}} every 12 months.
															</Trans>:
														(_gracePeriod || _renewalPrice) ?
															<Trans i18nKey="transBilledGracePeriodMonths">
																Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+_amount).toFixed(2)}} for the first {{trans24GracePeriod: _gracePeriod || 12}} months, then {{transBilledEvery12Months2: (CURRENCY[currency] || currency) + (+_renewalPrice).toFixed(2)}} every 12 months.
															</Trans> : 
															<Trans i18nKey="transBilledEvery12MonthsAlways">
																Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+_amount).toFixed(2)}} every 12 months.
															</Trans> :
													null
												}

												{p.packageIdentifier === PlanTypes.YEARLY2 ? 
													(couponValidPlan === PlanTypes.YEARLY2) ?
														(couponGracePeriod || couponRenewalPrice) ?
															<Trans i18nKey="transBilled24GracePeriodMonths">
																Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+couponPrice).toFixed(2)}} for the first {{trans24GracePeriod: couponGracePeriod || 24}} months, then {{transBilledEvery24Months2: (CURRENCY[currency] || currency) + (+couponRenewalPrice).toFixed(2)}} every 24 months.
															</Trans>:
															<Trans i18nKey="transBilled24Months">
																Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+couponRenewalPrice).toFixed(2)}} every 24 months.
															</Trans>:
														(_gracePeriod || _renewalPrice) ?
															<Trans i18nKey="transBilled24GracePeriodMonths">
																Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+_amount).toFixed(2)}} for the first {{trans24GracePeriod: _gracePeriod || 24}} months, then {{transBilledEvery24Months2: (CURRENCY[currency] || currency) + (+_renewalPrice).toFixed(2)}} every 24 months.
															</Trans> : 
															<Trans i18nKey="transBilled24Months">
																Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+_amount).toFixed(2)}} every 24 months.
															</Trans> :
													null
												}
											</div>: null
										}

										{planConfig.planShowBillShort ?
											<div className={classes.planOldPrice}>
												{p.packageIdentifier === PlanTypes.FREE ?
													t('Get 10GB of data every 30-days.') :
													null
												}

												{p.packageIdentifier === PlanTypes.MONTHLY ?
													t('Billed monthly') :
													null
												}

												{p.packageIdentifier === PlanTypes.YEARLY ?
													(couponValidPlan === PlanTypes.YEARLY) ?
														<Trans i18nKey="transBill12Months"> 
															Billed {{transBill12Months: (CURRENCY[currency] || currency) + (+couponPrice).toFixed(2)}}
														</Trans>: 
														<Trans i18nKey="transBill12Months">
															Billed {{transBill12Months: (CURRENCY[currency] || currency) + (+_amount).toFixed(2)}}
														</Trans>: 
													null
												}

												{p.packageIdentifier === PlanTypes.YEARLY2 ? 
													(couponValidPlan === PlanTypes.YEARLY2) ?
														<Trans i18nKey="transBill24Months">
															Billed {{transBill24Months: (CURRENCY[currency] || currency) + (+couponPrice).toFixed(2)}}
														</Trans>:
														<Trans i18nKey="transBill24Months">
															Billed {{transBill24Months: (CURRENCY[currency] || currency) + (+_amount).toFixed(2)}}
														</Trans> :
													null
												}
											</div>: null
										}

										<ul className={classes.planFeatureContainer}>
											<li className={classes.planFeature}>
												{t('Streaming Support')}
											</li>
											<li className={clsx(classes.planFeature, {[classes.planFeaturesExclamationIcon]: (p.packageIdentifier === PlanTypes.FREE)})}>
												{t(p.packageIdentifier === PlanTypes.FREE ? '10GB Data Every 30-Days' : 'Unlimited Data')}
											</li>
											<li className={clsx(classes.planFeature, {[classes.planFeaturesExclamationIcon]: (p.packageIdentifier === PlanTypes.FREE)})}>
												<Trans i18nKey="transCitiesCount" transCitiesCount={transCitiesCount}>
													Servers in {{transCitiesCount}} Cities
												</Trans>
											</li>
											<li className={clsx(classes.planFeature, {[classes.planFeaturesExclamationIcon]: (p.packageIdentifier === PlanTypes.FREE)})}>
												{t(p.packageIdentifier === PlanTypes.FREE ? '1 Connection' : '10 Connections')}
											</li>
											<li className={clsx(classes.planFeature, {[classes.planFeaturesDeleteIcon]: (p.packageIdentifier === PlanTypes.FREE)})}>
												{t('SOCKS5 Proxy')}
											</li>
											<li className={classes.planFeature}>
												{t('Unlimited Devices')}
											</li>
											<li className={classes.planFeature}>
												{t('Zero-Log')}
											</li>
											<li className={clsx(classes.planFeature, {[classes.planFeaturesDeleteIcon]: (p.packageIdentifier === PlanTypes.FREE)})}>
												{t('Ad Blocker')}
											</li>
											<li className={clsx(classes.planFeature, {[classes.planFeaturesDeleteIcon]: (p.packageIdentifier === PlanTypes.FREE)})}>
												{t('Parental Controls')}
											</li>
											<li className={clsx(classes.planFeature, {[classes.planFeaturesDeleteIcon]: (p.packageIdentifier === PlanTypes.FREE)})}>
												{t('Threat Prevention')}
											</li>
											<li className={clsx(classes.planFeature, classes.planFeatureIcons)}>
												{t('Apps for')}: {p.packageIdentifier === selectedPackage.packageIdentifier ? <img src={osIconsBlack} alt="" width="167" /> : <img src={osIconsGrey} alt="" width="167" /> }
											</li>
										</ul>

										{planConfig.sentry && _amountSentry ?
											<div className={classes.sentryContainer}>
												<FormControlLabel
													classes={{label: classes.sentryCheckboxLabelBox}}
													control={
														<Checkbox
															classes={{root:classes.sentryCheckbox}}
															checked={sentryProps[p.packageIdentifier] || false}
															onChange={e => handleUpdateSentryProps(e.target.checked, p.packageIdentifier)}
															color="primary"
														/>
													}
													label={
														<span className={classes.sentryCheckboxLabel}>
															<span>
																<Trans i18nKey="transAntivirusPrice">
																	Add {{transAntivirusPrice: _currency + _amountSentry}}/mo Antivirus
																</Trans>
															</span>
														</span>
													}
												/>
											</div>: null
										}

										{upgradeMode && p.packageIdentifier === PlanTypes.FREE ?
											!active_vpn ?
												<div className={clsx(classes.planButton, {[classes.planButtonUpgrade]: p.packageIdentifier === PlanTypes.FREE && upgradeMode, [classes.planButtonLoading]: btnProcessing})} onClick={handleReactivateFreemium}>
													{btnProcessing ? <LoaderSmall /> : null}
													{t(btnProcessing ? 'Processing' : (days_left_qty >= 0) ? 'Activate Limited Speed' : 'Refresh Data')}
												</div> :
												null :
											<div className={classes.planButton}>{t('Select')}</div>
										}


									</div>
								</CardContent>
								{(p.packageIdentifier === PlanTypes.YEARLY && !planConfig.hideYearlyMBG || p.packageIdentifier === PlanTypes.YEARLY2) ?
									<div className={classes.planFooterFlag}>
										{t('30-Day Money Back Guarantee')}
									</div> : null
								}

								{p.packageIdentifier === PlanTypes.FREE && selectedPackage.packageIdentifier === PlanTypes.FREE ?
									<div className={classes.planBottomInfo} onClick={(e) => handleClickSKU(e, yearlyPackage, true)}>
										<Trans i18nKey="transMessageCitiesCount" transMessageCitiesCount={transMessageCitiesCount}>
											Get unlimited data, servers in {{transMessageCitiesCount}} cities, and 10 connections with a 12 Month Plan.
										</Trans>
									</div>: null
								}
								{(p.packageIdentifier === PlanTypes.MONTHLY && selectedPackage.packageIdentifier === PlanTypes.MONTHLY) || (p.packageIdentifier === PlanTypes.YEARLY && selectedPackage.packageIdentifier === PlanTypes.YEARLY) ?
									<div className={clsx(classes.planBottomInfo, {[classes.planBottomInfoDe]: (i18n.resolvedLanguage === 'de')})} onClick={(e) => handleClickSKU(e, ((planConfig.showYearly2 || (upgradeMode && active_vpn)) ? yearly2Package : yearlyPackage), true)}>
										<Trans i18nKey="transCalloutMonthAndPercent">
											Get the best value with the {{transCalloutMonth: ((planConfig.showYearly2 || (upgradeMode && active_vpn)) ? 24 : 12)}} Month Plan and save {{transCalloutPercent: (((planConfig.showYearly2 || (upgradeMode && active_vpn)) ? saving2Percent : savingPercent) || planConfig.savingPercent || couponSavingPercent)}}.
										</Trans>
									</div>: null
								}
							</Card>
						</Grid>
					);
				})}
			</Grid>
		);
	};

	return selectedPackage ? (
		<div className={classes.plansWrapper} >
			<Container maxWidth="md" component="main" classes={{root: classes.packagesWrapper}}>
				<Grid container spacing={5} alignItems="center" justify="center">
					<Grid item xs={12}>
						<Typography component="h1" variant="h4" align="left" color="textPrimary" gutterBottom className={classes.sectionHeader}>
							{!planConfig.hidePlanSectionTitle && packages.length > 1 ? ('1.' + t('Choose Plan:')) : ''}
						</Typography>
					</Grid>
				</Grid>

				{_renderPlans()}
			</Container>

			{!planConfig.showShortPlans && planConfig.showFreeBtn ? 
				<Container maxWidth="md" component="div" className={classes.planFreeOpenBtnContainer}>
					<div className={clsx(classes.planButton)} onClick={handleOpenFreemiumModal}>{t('Free Plan')}</div>
				</Container> : null
			}

			{planConfig.showPlansSeparator ? 
				<Container maxWidth="md" component="div">
					<div className={classes.plansSeparator}></div>
				</Container> : null
			}

			{!planConfig.showShortPlans && showFreemiumModal ?
				<FreemiumModal 
					serversDetails={serversDetails}
					currency={CURRENCY[currency] || currency}
					freemiumModalPrice={_freemiumModalPrice}
					freemiumModalPercent={_freemiumModalPercent}
					onCloseFreemiumPopup={handleCloseFreemiumPopup} /> :
				null
			}
		</div>
	) : null;
}


const mapStateToProps = state => {
	return {
		sentryProps: state.home.sentryProps,
		couponByCode: state.home.couponByCode,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onReactivateFreemium: dataToSend => dispatch(reactivateFreemium(dataToSend)),
		onGetCoupon: (dataToSend) => dispatch(getCoupon(dataToSend)),
		onGetCouponByCode: (coupon, plan, addons) => dispatch(getCouponByCode(coupon, plan, addons)),
		onResetCouponByCode: prevCode => dispatch(resetCouponByCode(prevCode)),
		onUpdateSentryProps: sentryProps => dispatch(updateSentryProps(sentryProps)),
	};
};

PlansSection.propTypes = {
	i18n: PropTypes.object,
	upgradeMode: PropTypes.bool,
	sentryProps: PropTypes.object,
	showFreemiumModal: PropTypes.bool,
	queryParams: PropTypes.object,
	userstatus: PropTypes.string,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponSavingPercent: PropTypes.string,
	couponRenewalPrice: PropTypes.number,
	couponByCode: PropTypes.object,
	serversDetails: PropTypes.object,
	planConfig: PropTypes.object,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	packages: PropTypes.array,
	classes: PropTypes.object,
	currency: PropTypes.string,
	yearlyPackage: PropTypes.object,
	yearly2Package: PropTypes.object,
	monthlyPrice: PropTypes.any,
	selectedPackage: PropTypes.object,
	handleSelectPackage: PropTypes.func,
	handleScrollToPaymentSection: PropTypes.func,
	onReactivateFreemium: PropTypes.func,
	onGetCoupon: PropTypes.func,
	onToggleFreemiumModal: PropTypes.func,
	navigate: PropTypes.func,
	t: PropTypes.func,
	onUpdateSentryProps: PropTypes.func,
	onGetCouponByCode: PropTypes.func,
	onResetCouponByCode: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withNamespaces()(PlansSection)));
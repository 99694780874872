import iconMoneyBack from '../../assets/images/icon-moneyback-guarantee-check.png';
import checkMarkGreen from '../../assets/images/checked-green.png';
import deleteRed from '../../assets/images/icon-delete-red2.png';
import exclamation from '../../assets/images/icon-exclamation-mark.png';

export const useStyles = (theme) => ({
	hide: {
		display: 'none !important',
	},
	textBold: {
		fontWeight: '700 !important',
	},
	textBig: {
		fontSize: '20px !important',
	},
	textRed: {
		color: '#E52866',
	},
	pt10: {
		paddingTop: 10,
	},
	lineThrough: {
		textDecoration: 'line-through',
	},
	plansWrapper: {

	},
	pageHeader: {
		padding: theme.spacing(7, 0, 4),
		color: '#283339',
		fontFamily: 'Spartan, sans-serif',
		'& h1': {
			fontSize: 44,
			fontWeight: 700,
			lineHeight: '58px',
			letterSpacing: '-1px',
			margin: '0 0 10px 0',
		},
		'& h2': {
			fontSize: 18,
			fontWeight: 600,
			lineHeight: '30px',
			letterSpacing: '-1px',
			margin: '0 auto',
		},
		'& p': {
			fontSize: 18,
			fontWeight: 700,
			color: '#6d7173',
			fontFamily: 'Spartan, sans-serif',
			marginBottom: 30,
		}
	},
	sectionHeader: {
		color: '#6d7173',
		fontSize: 20,
		fontWeight: 700,
		lineHeight: '28px',
		padding: '10px 15px 20px',
		margin: 0,
	},
	packagesWrapper: {
		paddingBottom: 60,
		marginBottom: 0,
	},
	packageContainer: {
		maxWidth: '390px !important',
	},
	card: {
		position: 'relative',
		zIndex: 100,
		overflow: 'visible !important',
		borderRadius: 10,
		cursor: 'pointer',
		boxShadow: 'none',
		backgroundColor: '#ffffff',
		border: '4px solid transparent',
		minHeight: 330,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		padding: '50px 15px 30px',
		minWidth: 'calc(100% - 8px)',
	},
	cardHeaderBadge: {
		position: 'absolute',
		top: '-21px',
		left: 'calc(50% - 80px)',
		minWidth: '160px',
		textAlign: 'center',
		fontSize: 14,
		lineHeight: '24px',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
		padding: '6px 10px',
		background: '#28d799',
		zIndex: 2,
		color: '#fff',
		letterSpacing: '0px',
		borderRadius: 30,
	},
	cardHeader: {
		padding: 0,
		marginBottom: 6,
		'& span': {
			color: '#525B60',
			fontSize: 18,
			fontWeight: 700,
			fontFamily: 'Spartan, sans-serif',
			letterSpacing: '-0.5px',
		}
	},
	cardContent: {
		width: '100%',
		padding: '0px !important',
	},
	cardUserPercentage: {
		left: 0,
		right: 0,
		bottom: '-44px',
		position: 'absolute',
		fontSize: '13px',
		fontWeight: 700,
		textAlign: 'center',
		color:'#1a235b',
		padding: 9,
		background: '#e1f8f1',
		borderRadius: '6px',
	},
	cardUserPercentageIcon: {
		transform: 'rotate(-81deg)',
		display: 'inline-block',
		fontSize: '12px !important',
	},
	planPriceWrapper: {
		marginLeft: '-16px',
		marginRight: '-16px',
		paddingLeft: 16,
		paddingRight: 16,
		textAlign: 'center',
	},
	planPrice: {
		fontSize: 48,
		color: '#525B60',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
		textAlign: 'center',
		'& $currency': {
			fontSize: 20,
			position: 'relative',
			top: '-14px',
		},
		'& $month': {
			fontSize: 14,
		},
	},
	planPriceShort: {
		fontSize: 18,
		marginTop: 5,
		marginBottom: 15,
	},
	planOldPrice: {
		fontSize: 15,
		lineHeight: '24px',
		letterSpacing: '-0.3px',
		color: '#A6A9AB',
		fontWeight: 600,
		fontFamily: 'Spartan, sans-serif',
		textAlign: 'center',
		marginBottom: 10,
		'& p': {
			margin: '3px 0 0 0',
		}
	},
	planShowBill: {
		'& $planOldPrice': {
			marginBottom: 18,
		},
		'& $cardHeader': {
			marginBottom: 25,
		}
	},
	planCustomTotalPrice: {
		fontSize: 16,
		color: '#31c261',
		fontWeight: 700,
	},
	planOldTotalPriceDeleted: {
		color: '#fc1028',
		textDecoration: 'line-through',
	},
	currency: {

	},
	month: {

	},
	planButton: {
		background: '#D4D6D7',
		borderRadius: 30,
		textAlign: 'center',
		fontSize: 16,
		fontWeight: 700,
		color: '#ffffff',
		padding: '18px 10px',
		marginTop: 30,
	},
	planButtonUpgrade: {
		background: '#5058C8',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	planFreeOpenBtnContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		'& $planButton': {
			padding: '18px 30px',
			cursor: 'pointer',
			margin: 0,
		}
	},
	planButtonLoading: {
		background: '#D4D6D7',
		cursor: 'not-allowed',
	},
	planBottomInfo: {
		left: 0,
		right: 0,
		bottom: '-80px',
		position: 'absolute',
		fontSize: 13,
		fontWeight: 700,
		color:'#ffffff',
		padding: '15px 20px',
		backgroundColor: '#5058C8',
		borderRadius: 10,
		textAlign: 'center',
		marginLeft: '-4px',
		marginRight: '-4px',
		boxShadow: '0px 13px 32px rgba(109, 113, 115, 0.35)',
	},
	planBottomInfoDe: {
		bottom: '-100px',
	},
	planFooterFlag: {
		position: 'relative',
		fontSize: 11,
		fontWeight: 700,
		lineHeight: '12px',
		color: '#525B60',
		padding: '5px 15px 5px 30px',
		textAlign: 'center',
		marginTop: 25,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: '-1px',
			left: 0,
			width: 23,
			height: 22,
			backgroundSize: 'cover',
			backgroundImage: `url(${iconMoneyBack})`,
		},
	},
	planFeatureContainer: {
		margin: '0 10px',
		display: 'inline-block',
		textAlign: 'left',
	},
	planFeature: {
		position: 'relative',
		paddingLeft: 20,
		fontSize: 13,
		lineHeight: '28px',
		color: '#525B60',
		fontWeight: 700,
		fontFamily: 'Spartan, sans-serif',
		margin: 0,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 5,
			left: 0,
			width: 16,
			height: 16,
			backgroundImage: `url(${checkMarkGreen})`,
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
		},
		'&$planFeaturesDeleteIcon': {
			'&::before': {
				content: '""',
				width: 14,
				height: 14,
				top: 5,
				backgroundImage: `url(${deleteRed})`,
			},
		},
		'&$planFeaturesExclamationIcon': {
			'&::before': {
				content: '""',
				width: 14,
				height: 14,
				top: 5,
				backgroundImage: `url(${exclamation})`,
			},
		},
	},
	planFeatureIcons: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		'& img': {
			marginLeft: 7,
		}
	},
	planFeaturesDeleteIcon: {},
	planFeaturesExclamationIcon: {},
	bestValuePackage: {
		'& $planOldPrice': {
			marginBottom: 10,
		}
	},
	freePackage: {
		'& $planPriceWrapper': {

		},
	},

	packageShort: {
		'&$packageContainer': {
			maxWidth: '360px !important',
		},
		'& $cardHeader': {
			'& span': {
				color: '#525B60',
				fontSize: 24,
			}
		},
		'& $card': {
			padding: '30px 20px 30px',
		},
		'& $planFeature': {
			color: '#525B60',
		},
	},
	activePackage: {
		'& $card': {
			minWidth: 'calc(100% - 8px)',
			backgroundColor: '#ffffff',
			border: '4px solid #5058c8',
			boxShadow: '0px 13px 32px rgba(109, 113, 115, 0.35)',
			transition: 'all 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		},
		'& $cardHeader': {
			'& span': {
				color: '#283339',
			}
		},
		'& $planPrice': {
			color: '#283339',
		},
		'& $planFeature': {
			color: '#283339',
		},
		'& $planButton': {
			background: '#FF8F00',
		},
		'& $planFooterFlag': {
			color: '#283339',
		},
	},
	upgradeMode: {
		'&$freePackage': {
			'& $card': {
				cursor: 'initial',
			}
		}
	},
	plansSeparator: {
		borderTop: '1px solid #E6E5E5',
		marginTop: 30,
		marginBottom: 30,
	},
	sentryContainer: {
		borderRadius: 4,
		background: '#F3F3FB',
		margin: '15px 15px 0',
		padding: 10,
		'& label': {
			display: 'flex',
			alignItems: 'flex-start',
			margin: 0,
		}
	},
	sentryCheckboxLabelBox: {
		width: '100%',
	},
	sentryCheckboxLabel: {
		position: 'relative',
		display: 'flex',
		fontSize: 14,
		lineHeight: '24px',
		fontWeight: 700,
		textAlign: 'left',
	},
	sentryCheckbox: {
		margin: '-9px 0 -9px -9px',
	},
	'@media (max-width: 959px)': {
		planBottomInfo: {
			bottom: '-90px',
			'& br': {
				display: 'none',
			},
		},

		planBottomInfoDe: {
			bottom: '-100px',
		},
		activePackage: {
			'&$packageContainer': {
				marginBottom: 80,
			}
		},
		cardHeader: {
			display: 'block',
			'&>div': {
				display: 'block',
			}
		},
		planPrice: {
			fontSize: 48,
		},
		bestValuePackage: {
			order: '0 !important',
			'& $planFeature': {
				marginBottom: 0,
			},
		},
		freePackage : {
			order: '10 !important',
		},
	},
	'@media (max-width: 460px)': {
		packagesWrapper: {
			paddingBottom: 55,
		},
		card: {
			padding: '50px 15px 30px',
		},
		bestValuePackage: {
			marginTop: 12,
		},
	}
});

import React from 'react';

function UserAvatar() {

	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2747_13466)">
				<path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12 11.7C13.4912 11.7 14.7 10.4912 14.7 9C14.7 7.50883 13.4912 6.3 12 6.3C10.5089 6.3 9.30005 7.50883 9.30005 9C9.30005 10.4912 10.5089 11.7 12 11.7ZM12 13.5C14.4853 13.5 16.5 11.4853 16.5 9C16.5 6.51472 14.4853 4.5 12 4.5C9.51476 4.5 7.50005 6.51472 7.50005 9C7.50005 11.4853 9.51476 13.5 12 13.5ZM17.25 18.3C17.085 18.3 16.905 18.255 16.755 18.15C16.754 18.15 16.743 18.1434 16.7224 18.1312C16.4485 17.9683 14.4839 16.8 12 16.8C9.4464 16.8 7.45531 18.0212 7.26039 18.1407L7.24505 18.15C6.82505 18.42 6.27005 18.315 6.00005 17.895C5.73005 17.475 5.83505 16.92 6.25505 16.65C6.25999 16.6472 6.27004 16.641 6.28505 16.6317C6.58907 16.4439 8.92677 15 12 15C15.225 15 17.655 16.59 17.745 16.65C18.165 16.92 18.27 17.475 18 17.895C17.82 18.15 17.535 18.3 17.25 18.3Z" fill="#525B60"/>
			</g>
			<defs>
				<clipPath id="clip0_2747_13466">
					<rect width="24" height="24" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default UserAvatar;